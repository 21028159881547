@import './variables.scss';
@import './mixins.scss';
@import './buttons.scss';

body {
  font-family: 'Nunito';
  letter-spacing: 0.0625em;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}
