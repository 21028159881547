@import '../../scss/variables';

.signup-section {
  padding: 10rem 0;
  background: linear-gradient(to bottom, #{fade-out($black, .9)} 0%,#{fade-out($black, .5)} 75%,$black 100%), url('../../img/bg-signup.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  .form-inline {
    input {
      box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0,0,0,.1) !important;
      padding: 1.25rem 2rem;
      height: auto;
      font-family: 'Varela Round';
      font-size: 80%;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      border: 0;
    }
  }
}
