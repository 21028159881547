@import '../../scss/variables';

.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
  background: linear-gradient(to bottom, #{fade-out($black, .7)} 0%,#{fade-out($black, .3)} 75%,$black 100%), url('../../img/bg-masthead.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    font-family: 'Varela Round';
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.8rem;
    background: -webkit-linear-gradient(fade-out(white, 0.1), fade-out(white, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  h2 {
    max-width: 20rem;
    font-size: 1rem;
  }
  @media(min-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media(min-width: 992px) {
    height: 100vh;
    padding: 0;
    h1 {
      font-size: 6.5rem;
      line-height: 6.5rem;
      letter-spacing: 0.8rem;
    }
    h2 {
      max-width: 30rem;
      font-size: 1.25rem;
    }
  }
}
